import React from 'react';
import { Link } from 'react-router-dom';

function Home() {
  return (
    <div id="main">

      {/* Hero Section */}
      <div id="hero">
        <div id="hero-styles">
          <div id="hero-caption" className="content-full-width parallax-scroll-caption text-align-center height-title"  data-bgcolor="#0c0c0c">
            <div className="inner">
              <h1 className="hero-title caption-timeline primary-font-title"><span>Montoya</span></h1>
              <div className="hero-subtitle caption-timeline">
                <span>WE ARE A CREATIVE STUDIO, SPECIALIZED IN STRATEGY, BRANDING <br className="destroy" /> DESIGN, AND DEVELOPMENT. OUR WORK IS ALWAYS AT THE INTERSECTION <br className="destroy" /> OF DESIGN AND TECHNOLOGY.</span>
              </div>
            </div>
          </div>
          <div id="hero-footer">
            <div className="hero-footer-left">
              <div className="button-wrap right scroll-down">
                <div className="icon-wrap parallax-wrap">
                  <div className="button-icon parallax-element">
                    <i className="fa-solid fa-angle-down"></i>
                  </div>
                </div>
                <div className="button-text sticky right"><span data-hover="Scroll to Explore">Scroll to Explore</span></div>
              </div>
            </div>
            <div className="hero-footer-right">
              <div id="info-text">Featured Projects</div>
            </div>
          </div>
        </div>
      </div>
      {/* /Hero Section */}

      {/* Main Content */}
      <div id="main-content">

        {/* Main Page Content */}
        <div id="main-page-content" className="content-full-width">

          {/* Row */}
          <div className="content-row full text-align-center row_padding_left row_padding_right dark-section" data-bgcolor="#0c0c0c">

            <div id="itemsWrapperLinks">
              <div id="itemsWrapper" className="webgl-fitthumbs fx-one">

                <div className="overlapping-gallery">

                  {/* Overlapping Image Examples */}
                  {[
                    { img: 'images/01hero.jpg', title: 'White Liners', year: '2024', category: 'Photography', link: 'project01.html' },
                    { img: 'images/02hero.jpg', title: 'Green Audio', year: '2024', category: 'Photography', link: 'project02.html' },
                    { img: 'images/03hero.jpg', title: 'Nanotech', year: '2024', category: 'Branding', link: 'project03.html' },
                    { img: 'images/04hero.jpg', title: 'Cool Dude', year: '2024', category: 'Video Production', link: 'project04.html', video: 'images/04hero.mp4' },
                    { img: 'images/05hero.jpg', title: 'Sphere Digital', year: '2024', category: 'Graphic Design', link: 'project05.html' }
                  ].map((item, index) => (
                    <div key={index} className="overlapping-image">
                      <div className="overlapping-image-inner trigger-item" data-centerline="OPEN">
                        <div className="img-mask">
                          <Link className="slide-link" to={item.link} data-type="page-transition"></Link>
                          <div className="section-image trigger-item-link">
                            <img src={item.img} className="item-image grid__item-img" alt="" />
                            
                          </div>
                          <img src={item.img} className="grid__item-img grid__item-img--large" alt="" />
                        </div>
                        <div className="slide-caption trigger-item-link-secondary">
                          <div className="slide-title primary-font-title"><span>{item.title}</span></div>
                          <div className="slide-date"><span>{item.year}</span></div>
                          <div className="slide-cat"><span>{item.category}</span></div>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>

              </div>
            </div>

            <hr />

            <p>
              <span className="has-opacity">CRAFTING WEBSITES WHERE THE ELEGANCE</span>
              <br className="destroy" />
              <span className="has-opacity">OF DESIGN INTERSECTS WITH THE SCIENCE OF SELLING PRODUCTS.</span>
            </p>

            <div className="button-box text-align-center has-animation fadeout-element">
              <div className="clapat-button-wrap parallax-wrap hide-ball">
                <div className="clapat-button parallax-element">
                  <div className="button-border outline rounded parallax-element-second">
                    <Link className="ajax-link" to="/index-showcase-grid" data-type="page-transition">
                      <span data-hover="See All Works">See All Works</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

          </div>
          {/* /Row */}

          {/* Skills Row */}
          <div className="content-row full row_padding_left row_padding_right dark-section fadeout-element" data-bgcolor="#0c0c0c">
            <div className="list-rotator-wrapper">
              <div className="list-rotator-title">OUR SKILLS COVER</div>
              <div className="list-rotator-pin">
                <ul className="list-rotator primary-font-title">
                  <li>Web Design</li>
                  <li>Mobile App Development</li>
                  <li>UI/UX Design</li>
                  <li>SEO Optimization</li>
                  <li>Social Media Management</li>
                  <li>Data Analytics</li>
                  <li>Content Creation</li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Skills Row */}

          {/* Contact Info Row */}
          <div className="content-row dark-section text-align-center" data-bgcolor="#0c0c0c">

            <div className="one_third has-animation" data-delay="100">
              <div className="box-icon-wrapper block-boxes">
                <div className="box-icon">
                  <i className="fa fa-paper-plane fa-2x" aria-hidden="true"></i>
                </div>
                <div className="box-icon-content">
                  <h6 className="no-margins"><a href="mailto:office@montoya.com" className="link"><span>office@montoya.com</span></a></h6>
                  <p>Email</p>
                </div>
              </div>
            </div>

            <div className="one_third has-animation" data-delay="200">
              <div className="box-icon-wrapper block-boxes">
                <div className="box-icon">
                  <i className="fa fa-map-marker fa-2x" aria-hidden="true"></i>
                </div>
                <div className="box-icon-content">
                  <h6 className="no-margins">35 M Str, New York, USA</h6>
                  <p>Address</p>
                </div>
              </div>
            </div>

            <div className="one_third last has-animation" data-delay="300">
              <div className="box-icon-wrapper block-boxes">
                <div className="box-icon">
                  <i className="fa fa-phone fa-2x" aria-hidden="true"></i>
                </div>
                <div className="box-icon-content">
                  <h6 className="no-margins">0040 (7763) 574-8901</h6>
                  <p>Phone</p>
                </div>
              </div>
            </div>

          </div>
          {/* /Contact Info Row */}

        </div>
        {/* /Main Page Content */}

        {/* Page Navigation */}
        <div id="page-nav">
          <div className="page-nav-wrap">
            <div className="page-nav-caption content-full-width text-align-center height-title">
              <div className="inner">
                <div className="next-hero-subtitle caption-timeline"><span>EXPLORING OUR WORLD OF VISUAL AND INTERACTIVE DESIGN</span></div>
                <Link className="page-title next-ajax-link-page" to="/about" data-type="page-transition" data-centerline="ABOUT US">
                  <div className="next-hero-title primary-font-title caption-timeline"><span>Our Studio</span></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Navigation */}

      </div>
      {/* /Main Content */}
      <div id="app"></div>

    </div> 

    
  );
}



export default Home;
