import React from 'react';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <header className="fullscreen-menu invert-header" data-menucolor="#0c0c0c">
      <div id="header-container">

        {/* Logo */}
        <div id="logo" className="hide-ball">
          <Link to="/" className="ajax-link" data-type="page-transition">
            <img className="black-logo" src="%PUBLIC_URL%/assets/images/logo.png" alt="ClaPat Logo" />
            <img className="white-logo" src="%PUBLIC_URL%/assets/images/logo-white.png" alt="ClaPat Logo" />
          </Link>
        </div>
        {/* /Logo */}

        {/* Navigation */}
        <nav>
          <div className="nav-height">
            <div className="outer">
              <div className="inner">
                <ul data-breakpoint="10025" className="flexnav">
                  <li className="link menu-timeline">
                    <button type="button"><div className="before-span"><span data-hover="Portfolio">Portfolio</span></div></button>
                    <ul>
                      <li><Link to="/" className="ajax-link active" data-type="page-transition">Overlapping Gallery</Link></li>
                      <li><Link to="/portfolio-grid" className="ajax-link" data-type="page-transition">Portfolio Grid</Link></li>
                      <li><Link to="/archive-gallery" className="ajax-link" data-type="page-transition">Archive Gallery</Link></li>
                    </ul>
                  </li>
                  <li className="link menu-timeline">
                    <Link to="/about" className="ajax-link" data-type="page-transition">
                      <div className="before-span"><span data-hover="About">About</span></div>
                    </Link>
                  </li>
                  <li className="link menu-timeline">
                    <Link to="/contact" className="ajax-link" data-type="page-transition">
                      <div className="before-span"><span data-hover="Contact">Contact</span></div>
                    </Link>
                  </li>
                  <li className="link menu-timeline">
                    <button type="button"><div className="before-span"><span data-hover="More">More</span></div></button>
                    <ul>
                      <li><Link to="/typography" className="ajax-link" data-type="page-transition">Typography</Link></li>
                      <li><Link to="/multimedia" className="ajax-link" data-type="page-transition">Multimedia</Link></li>
                      <li><Link to="/shortcodes" className="ajax-link" data-type="page-transition">Shortcodes</Link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        {/* /Navigation */}

        {/* Menu Burger */}
        <div className="button-wrap right menu burger-lines">
          <div className="icon-wrap parallax-wrap">
            <div className="button-icon parallax-element">
              <div id="burger-wrapper">
                <div id="menu-burger">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
            </div>
          </div>
          <div className="button-text sticky right"><span data-hover="Menu">Menu</span></div>
        </div>
        {/* /Menu Burger */}

      </div>
    </header>
  );
}

export default Header;
