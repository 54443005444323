// src/services/portfolioService.js

const portfolioData = [
  {
    id: 1,
    name: '3D Animasyon',
    templateId: 2,
    projects: [
      { id: 1, name: 'Getek Dora Vision M200', templateId: 2 },
      { id: 2, name: 'Volly Technology Volly Pro One', templateId: 2 }
    ]
  },
  {
    id: 2,
    name: 'Grafik Tasarım',
    templateId: 2,
    projects: [
      { id: 3, name: 'Metlox Black Selection Series', templateId: 1 },
      { id: 4, name: 'Cactus Deep Kutu Oyunu', templateId: 1 }
    ]
  },
  {
    id: 3,
    name: 'Web Tasarım',
    templateId: 2,
    projects: [
      { id: 5, name: 'SiteGround Pro Web', templateId: 2 },
      { id: 6, name: 'OceanWP Ultimate Design', templateId: 2 }
    ]
  }
];

// Menü öğelerini portfolyo verilerinden türeten bir fonksiyon
export const getPortfolioMenuItems = () => {
  return portfolioData.map((category) => ({
    id: category.id,
    name: category.name
  }));
};

// Tüm portfolyo verilerini döndüren fonksiyon
export const getPortfolioData = () => {
  return portfolioData;
};
