import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Preloader from './components/Preloader';
import Home from './pages/Home';
import { getPortfolioMenuItems } from './services/portfolioService';

function App() {
  const portfolioMenuItems = getPortfolioMenuItems();

  return (
    <Router>
      <main>	
      <Preloader />
      <div className="cd-index cd-main-content">
        <div id="clapat-page-content" className="light-content" data-bgcolor="#0c0c0c">
          {/* Header Component */}
          <Header menuItems={portfolioMenuItems} />
          
          <div id="content-scroll">
            {/* Home Component */}
            <Routes>
              <Route path="/" element={<Home />} />
              {/* Diğer sayfalar için yönlendirmeler buraya eklenebilir */}
            </Routes>

            {/* Footer Component */}
            <Footer />
          </div>
          <div id="app"></div>
        </div>
      </div>
      </main>
    </Router>
  );
}

export default App;
